import { gql } from "@apollo/client";

export const GET_REPORT = gql`
  query Report($sizingReportId: String!) {
    report(sizingReportId: $sizingReportId) {
      createdAt
      id
      memoryRequirement
      memoryRequirementAfterOptimization
      memoryRequirementForShadowInstance
      sapsCategory
      sid
      sizingReportFile
      updatedAt
    }
  }
`;

export const REGIONS = gql`
  query Regions($vendor: String) {
    regions(vendor: $vendor) {
      id
      code
      location
      vendor
    }
  }
`;
export const SELECTEDREGION = gql`
  query RegionSelect($vendor: String!, $regionSelectId: Int) {
    regionSelect(vendor: $vendor, id: $regionSelectId) {
      id
      vendor
      code
      location
    }
  }
`;

export const INSTANCE = gql`
  query Instances($regionId: Int!, $vendor: String) {
    instances(regionId: $regionId, vendor: $vendor) {
      code
      memory
      vcpu
      isCertified
    }
  }
`;

export const DB_INSTANCE_RECOMMENDATION = gql`
  query DbServerRecommendation($input: DBInstanceInput) {
    dbServerRecommendation(input: $input) {
      primary {
        code
        memory
        vcpu
        isCertified
      }
      backup {
        code
        memory
        vcpu
        isCertified
      }
    }
  }
`;

export const APPLICATION_INSTANCE_RECOMENDATION = gql`
  query AppServerRecommendation($input: ApplicationInstanceInput) {
    appServerRecommendation(input: $input) {
      primary {
        code
        memory
        vcpu
        isCertified
      }
      backup {
        code
        memory
        vcpu
        isCertified
      }
    }
  }
`;
export const instancePricing = gql`
  query InstancePricings($input: [InstancesPrice]) {
    instancePricings(input: $input) {
      dbSizing {
        id
        sid
        memoryRequirement
        memoryRequirementAfterOptimization
        memoryRequirementForShadowInstance
        sapsCategory
        sizingReportFile
      }
      prices {
        instance
        type
        regionId
        dimensions {
          unit
          amount
        }
        os
      }
    }
  }
`;
export const GET_REPORT_CONFIGURATIONS = gql`
  query GetSizingReportConfiguration($id: String) {
    getSizingReportConfiguration(cid: $id) {
      id
      step
      sizingReportId
      includeHA
      includeDR
      shadowInstance
      cleanUp
      vendor
      primaryRegionId
      backupRegionId
      primaryDBServerInstance
      backupDBServerInstance
      amount
      isCompleted
      dbOperatingSystem
      dbOsLicenseModel

      AppServerConfigurations {
        id
        appOsLicenseModel
        appOperatingSystem
        primaryAppServerInstance
        backupAppServerInstance
        applicationServerSize
        appServerCount
        appDRServerCount
      }
      SizingReport {
        id
        sid
        memoryRequirement
        memoryRequirementAfterOptimization
        memoryRequirementForShadowInstance
        sapsCategory
        sizingReportFile
      }
      awsConfiguration {
        tenancy
        paymentOption
        reservationTerm
        purchaseOption
      }
      gcpConfiguration {
        committedUsage
        paymentOption
      }
      azureConfiguration {
        purchaseOption
        paymentOption
        tier
      }
    }
  }
`;

export const GET_CONTAINER_DATA = gql`
  query containers {
    containers {
      containerName
      backupRegionId
      createdAt
      id
      paymentPlan
      platform
      primaryRegionId
      status
      totalAmount
      updatedAt
    }
  }
`;

export const GET_Container = gql`
  query getContainerReports($cid: String!) {
    getContainerReports(cid: $cid) {
      id
      sid
      sizingReportConfigId
      memoryRequirement
      memoryRequirementAfterOptimization
      memoryRequirementForShadowInstance
      sapsCategory
      sizingReportFile
      createdAt
      updatedAt
      completedAt
      isCompleted
      reportModifiedAt
      amount
    }
  }
`;
export const GET_TIER = gql`
  query GetTiers {
    getTiers {
      id
      tierName
    }
  }
`;
export const GET_Container_SID = gql`
  query getContainerReports($cid: String!) {
    getContainerReports(cid: $cid) {
      id
      sid
      sizingReportConfigId
    }
  }
`;

export const GET_Conatiner_Name = gql`
  query ReportContainer($containerId: String!) {
    reportContainer(containerId: $containerId) {
      id
      status
      platform
      paymentPlan
      containerName
      totalAmount
      createdAt
      updatedAt
    }
  }
`;

export const SID_VALIDATION = gql`
  query Query($sid: String!, $containerId: String!) {
    checkUniqueSid(sid: $sid, containerId: $containerId)
  }
`;

export const CONTAINER_REPORT = gql`
  query GetContainerReports($cid: String!) {
    getContainerReports(cid: $cid) {
      id
      sid
      memoryRequirement
      sapsCategory
      sizingReportFile
      createdAt
      updatedAt
      tierId
      tierName
    }
  }
`;

export const STORAGE_CODE = gql`
  query GetDisksCode {
    getDisksCode {
      code
      type
      max_throughput
      vendor
    }
  }
`;
export const GET_DISTANCE_QUERY = gql`
  query GetDistance($region1: Int!, $region2: Int!) {
    getDistance(region1: $region1, region2: $region2) {
      distance
    }
  }
`;

// query GetFullConfiguration($cid: String) {
//   getFullConfiguration(cid: $cid) {
//     containerConfig {
//       appOsLicenseModel
//       backupRegionId
//       containerName
//       dbOsLicenseModel
//       includeDR
//       primaryRegionId
//       supportedDrSites
//       vendor
//     }
//     landscapes {
//       SizingReports {
//         DbandAppsConfig {
//           AppServerConfigurations {
//             appDRServerCount
//             appOperatingSystem
//             appServerCount
//             backupAppServerInstance
//             enableDR
//             id
//             primaryAppServerInstance
//             storageCode
//           storageType
//           }
//           backupDBServerInstance
//           dbHaCount
//           dbOperatingSystem
//           enableDR
//           id
//           primaryDBServerInstance
//           storageCode
//           storageType
//         }
//         id
//         sid
//       }
//       id
//       tierName
//     }
//   }
// }`;

export const GET_FULL_CONFIG = gql`
  query GetFullConfiguration($cid: String) {
    getFullConfiguration(cid: $cid) {
      additionalInfrastructure {
        id
        containerId
        cpu
        operatingSystem
        ram
        serverInstance
        storageCode
        storageSpace
        storageType
        count
      }
      containerConfig {
        containerName
        primaryRegionId
        backupRegionId
        appOsLicenseModel
        dbOsLicenseModel
        includeDR
        supportedDrSites
        vendor
        step
        appOperatingSystem
        dbOperatingSystem
        isLoadTemplate
        awsConfiguration {
          paymentOption
          tenancy
          reservationTerm
          purchaseOption
        }
        azureConfiguration {
          tier
          paymentOption
          purchaseOption
        }
        gcpConfiguration {
          committedUsage
          paymentOption
        }
      }
      landscapes {
        id
        tierName
        SizingReports {
          id
          sid
          memoryRequirement

          DbandAppsConfig {
            id
            primaryDBServerInstance
            backupDBServerInstance
            dbOperatingSystem
            dbHaCount
            enableDR
            storageCode
            storageType
            cpu
            storageSpace
            ram
            dbHaCountDR
            AppServerConfigurations {
              storageSpaceDR
              storageSpace
              ramDR
              ram
              cpuDR
              cpu
              storageTypeDR
              storageType
              storageCodeDR
              storageCode
              appDRServerCount
              appServerCount
              enableDR
              appDROperatingSystem
              appOperatingSystem
              backupAppServerInstance
              primaryAppServerInstance
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_FULL_SERVER_CONFIG = gql`
query FullServersConfig($cid: String!) {
  fullServersConfig(cid: $cid) {
    containerConfig {
      id
      containerName
      primaryRegionId
      backupRegionId
      totalAmount
      vendor
      status
      step
      platform
      paymentPlan
      includeHA
      includeDR
      selectedTiers {
        id
        tierName
      }
      createdAt
      updatedAt
    }
    landscapes {
      id
      tierName
      serversLabels {
        id
        label
        serverInstances {
          id
          purpose
          instanceCode
          cpu
          ram
          os
          licenseModel
          enableDR
          storageType
          storageCode
          storageSpace
          storageCost
          instanceCost
          totalCost
          createdAt
          updatedAt
        }
      }
    }
  }
}
`;

export const compareRecomendation = gql`
  query GetCompareRegionRecommendation($input: RegionCompare) {
    getCompareRegionRecommendation(input: $input) {
      compareRegionId
      compareVendor
      distance
      code
      location
    }
  }
`;

export const generateTerraformFiles = gql`
  mutation GenerateTerraformFiles($input: GenerateTerraformFilesInput!) {
    generateTerraformFiles(input: $input) {
      url
    }
  }
`;

export const instanceTierPricings = gql`
  query InstanceTierPricings($input: PricingQueryInput) {
    instanceTierPricings(input: $input) {
      prices {
        tier
        sid
        cpu
        memoryRequirement
        instance
        type
        regionId

        dimensions {
          unit
          amount
        }
        storage {
          amount
          unit
        }
        os
      }
      awsConfiguration {
        tenancy
        paymentOption
        reservationTerm
        purchaseOption
      }
      azureConfiguration {
        tier
        purchaseOption
        paymentOption
      }
      gcpConfiguration {
        committedUsage
        paymentOption
      }
      prices {
        tier
        sid
        memoryRequirement
        cpu
        instance
        type
        regionId
        os
        storageCode
        storageType
        storageSpace
        dimensions {
          unit
          amount
        }
        storage {
          amount
          unit
        }
      }
    }
  }
`;

export const CHECK_EMPTY_CONTAINER = gql`
  query Query($cid: String!) {
    checkIsContainerEmpty(cid: $cid)
  }
`;
// primaryRegionId
// backupRegionId
export const TEMPLATE_PACKAGE = gql`
query ListTemplatePackages($input: listTemplatePackagesInput!) {
  listTemplatePackages(input: $input) {
    id
    architectureType
    packageSizeName
    vendor
    reports {
      sid
      tierId
      memoryRequirement
    }
  }
}
`;

export const GET_SERVER_CATALOGUE_LIST = gql`
  query GetServerCatalogueList($searchString: String, $page: Int, $pageSize: Int) {
    getServerCatalogueList(searchString: $searchString, page: $page, pageSize: $pageSize) {
      data {
        id
        serviceName
        slaEligibility
        serverSizes {
          id
          size
          database
          slaEligibility
          published
          systemTiers {
            name
            dbSize
            dbServer
            appServer
          }
        }
        createdAt
        updatedAt
      }
      page
      pageSize
      pagesCount
      totalRecords
      nextPage
    }
  }
`;

export const GET_DETAILINFO_CATALOGUE = gql`
  query GetCatalogueDetail($catalogueId: ID!) {
    getCatalogueDetail(catalogueId: $catalogueId) {
      id
      serviceName
      slaEligibility
      serverSizes {
        id
        size
        usageTier {
          label
        }
        databaseType {
          label
          type1
          type2
          type3
        }
        prdSize {
          label
          type1
          type2
          type3
        }
        qasSize {
          label
          type1
          type2
          type3
        }
        devSize {
          label
          type1
          type2
          type3
        }
        nonProdSize {
          label
          type1
          type2
          type3
        }
      }
    }
  }
`;

export const GET_COUNT_FOR_COMPARE = gql`
  query GetCompareCount($deviceid: String!) {
    getCompareCount(deviceid: $deviceid) {
      compareCount
    }
  }
`;

export const SET_COUNT_FOR_COMPARE = gql`
  query IncrementCompareCount($deviceid: String, $count: Int) {
    incrementCompareCount(deviceid: $deviceid, count: $count) {
      compareCount
    }
  }
`;


export const ServerInstancePricings = gql`
query ServerInstancePricings($input: ServerConfigInput) {
  serverInstancePricings(input: $input) {
    awsConfiguration {
        tenancy
        paymentOption
        reservationTerm
        purchaseOption
      }
      azureConfiguration {
        tier
        purchaseOption
        paymentOption
      }
      gcpConfiguration {
        committedUsage
        paymentOption
      }
    prices {
      tier
      system
      cpu
      memoryRequirement
      instance
      purpose
      ram
      regionId
      dimensions {
        unit
        amount
      }
      storage {
        amount
        unit
      }
      os
      storageCode
      storageType
      storageSpace
    }
  }
}
`